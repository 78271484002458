import React from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import SectionHeader from '../../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../../shared/components/SectionBody/SectionSubHeader'

const SingleItemHero = () => {

    const header = 'Product Detail',
        subHeader = "Detail of the product"
    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <div className='flex justify-start items-center'>
                        <h1 className='font-bold text-[#1D242E]/50 text-[24px]'>
                            Inventory
                        </h1>
                        <div>
                            <RiArrowDropRightLine size={24} />
                        </div>
                        <SectionHeader header={header} />
                    </div>
                    <SectionSubHeader subHeader={subHeader} />
                </div>
            </div>
        </div>

    )
}

export default SingleItemHero