import React from 'react'
import { TbListDetails } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom';

const ActionButton = () => {

    return (
        <div class="inline-flex rounded-md shadow-sm" role="group">
            <Link to='/' class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-100 bg-blue-700 hover:text-blue-700 hover:bg-white border border-gray-200 rounded-s-lg  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white transition-all duration-300">


                <TbListDetails />
                <span className='pl-2'>
                    View

                </span>
            </Link>

            <Link to="/button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-green-500 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                <svg class="w-3 h-3 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 12.25V1m0 11.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M4 19v-2.25m6-13.5V1m0 2.25a2.25 2.25 0 0 0 0 4.5m0-4.5a2.25 2.25 0 0 1 0 4.5M10 19V7.75m6 4.5V1m0 11.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM16 19v-2" />
                </svg>
                Edit
            </Link>

            <Link to="/button" class="inline-flex items-center px-4 py-2 text-sm font-medium hover:text-red-700 hover:bg-white border border-gray-200 rounded-e-lg text-gray-100 bg-red-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white transition-all duration-300">

                <MdDeleteOutline />
                <span className='pl-2'>Delete</span>
            </Link>
        </div>
    )
}

export default ActionButton