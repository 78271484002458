/**
 * @param params
 * @description it contains multiple endpoint is key-value pair
 * @return String
 */

export const endPoint = {
  inventory: "https://api.dawaafinder.com/inventoryapi/",
  product: "https://api.dawaafinder.com/productapi/",
};
