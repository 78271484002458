import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/apiService";

// Unique action type for getting all medicine formularies
export const getAllMedicineFormularies = createAsyncThunk(
  "Formulary/getAllMedicineFormularies",
  async (_, { rejectWithValue }) => {
    try {
      // const response = await api.get("formularyapi/medicine-formularies/");
      const response = await api.get("medicine-formularies/");
      // console.log(response.data.data.medicineFormularies)
      return response.data.data.medicineFormularies
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Unique action type for getting medicine categories
export const getMedicineCategories = createAsyncThunk(
  "Formulary/getMedicineCategories",
  async (_, { rejectWithValue }) => {
    try {
      // const response = await api.get("formularyapi/medicine-formularies/");
      const response = await api.get("categories/");
      console.log(response.data);
      return response.data.categories;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Unique action type for adding a medicine formulary
export const addMedicineFormulary = createAsyncThunk(
  "Formulary/addMedicineFormulary",
  async (medicineData, { dispatch, rejectWithValue }) => {
    try {
      // const response = await api.post(
      //   "formularyapi/medicine-formularies/",
      //   medicineData
      // );
      const response = await api.post("medicine-formularies/", medicineData);

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);
