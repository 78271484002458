import React from 'react'

const DateRange = () => {
    return (
        <div>
            <h3>Data Range</h3>

            <div class=" bg-slate-200/80 rounded border border-gray-800/30">

                <p class="px-3 py-2 text-gray-800 text-sm font-normal font-poppins">01 Dec 2021- 31 Dec 2021</p>

            </div>
        </div>
    )
}

export default DateRange