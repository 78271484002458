import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import InventoryDesc from './InventoryDesc'
import { MdAdd } from "react-icons/md";
import { Link } from 'react-router-dom';
import NewAddedInventories from './NewAddedInventories/NewAddedInventories';
import RunningLowStocks from './RunningLowStocks/RunningLowStocks';



const InventoryHome = () => {
    const header = 'Inventory',
        subHeader = "Quick overview"

    return (
        <div className='flex flex-col gap-6'>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <SectionHeader header={header} />
                    <SectionSubHeader subHeader={subHeader} />
                </div>
                <Link to='/inventory/add-item' className={` bg-[#F0483E] border text-white rounded p-3 flex justify-between items-center cursor-pointer`} >
                    <div>
                        <MdAdd size={20} fill='#fff' />
                    </div>
                    <h4>Add New Item</h4>
                </Link>

            </div>
            <InventoryDesc />
            <NewAddedInventories />
            <RunningLowStocks />
        </div>
    )
}

export default InventoryHome
