import React from 'react'
import NationalMedicineFrmularyHome from '../Components/NationalMedicineFrmularyHome'

const NationalMedicineFormulary = () => {
    return (
        <div className='pl-8 pb-4'>

            <NationalMedicineFrmularyHome />
        </div>
    )
}

export default NationalMedicineFormulary