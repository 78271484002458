import React from 'react'
import Main from '../Components/Main'

const LoginPage = () => {
    return (
        <div>
            <Main />
        </div>
    )
}

export default LoginPage