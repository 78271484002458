import React, { useState } from "react";
import RegisterForm from "../Component/RegisterForm";
import MapContainers from "../Component/MapContainers";

const RegisterPage = () => {
  const [coordinates, setCoordinates] = useState([7.6731, 36.8356]);

  const onSubmit = (data) => {
    console.log({ ...data, coordinates });
  };

  return (
    <div className="flex flex-col md:flex-row p-6">
      <RegisterForm onSubmit={onSubmit} />
      <MapContainers
        coordinates={coordinates}
        setCoordinates={setCoordinates}
      />
    </div>
  );
};

export default RegisterPage;
