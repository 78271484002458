import React from 'react'
import MedicineGroupsHome from '../Components/MedicineGroupsHome'

const MedicineGroupsPages = () => {
    return (
        <div className='pl-8 pb-4'>
            <MedicineGroupsHome />
        </div>
    )
}

export default MedicineGroupsPages