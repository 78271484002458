import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import ReportDesc from './ReportDesc'



const ReportHome = () => {
    const header = 'Inventory',
        subHeader = "Quick overview"

    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <SectionHeader header={header} />
                    <SectionSubHeader subHeader={subHeader} />
                </div>

            </div>
            <ReportDesc />
        </div>
    )
}

export default ReportHome
