import React from 'react'

const CardBody = ({ value, label }) => {
    return (
        <div>

            <h2 className='text-gray-800 text-xl font-bold font-Poppins leading-snug'>{value}</h2>
            <h4 class="text-gray-800 text-sm font-medium font-Poppins leading-snug">{label}</h4>
        </div>
    )
}

export default CardBody