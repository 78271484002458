import React from "react";
import Card from "../../../shared/components/Cards/Card";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { MdOutlineHealthAndSafety, MdOutlineReceiptLong } from "react-icons/md";
const DashboardDesc = () => {

    const dashboardData = [
        {
            icon: <MdOutlineHealthAndSafety color="#01A768" size={37} />,
            header: "Good",
            subHeader: "Inventory Status",
            path: "/inventory-status",
            borderColor: "border-[#01A768]",
            bottomSectionBg: "bg-[#01A768]",

        },
        {
            icon: <MdOutlineReceiptLong color="#FED600" size={37} />,
            header: "ETB 30,509",
            subHeader: "Revenue: Aug, 2023",
            path: "/inventory-status",
            borderColor: "border-[#FED600]",
            bottomSectionBg: "bg-[#FED600]",

        },
        {
            icon: <AiOutlineMedicineBox color="#03A9F5" size={37} />,
            header: "298",
            subHeader: "Medicine Available ",
            path: "/inventory-status",
            borderColor: "border-[#03A9F5]",
            bottomSectionBg: "bg-[#03A9F5]",

        },
        {
            icon: <MdOutlineHealthAndSafety color="#F0483E" size={37} />,
            header: "Good",
            subHeader: "Inventory Status",
            path: "/inventory-status",
            borderColor: "border-[#F0483E]",
            bottomSectionBg: "bg-[#F0483E]",

        }
    ]

    const returnData = dashboardData.map((data, _index) => (
        <Card
            key={_index}
            data={data}
            icon={data.icon}
            header={data.header}
            subHeader={data.subHeader}
            path={data.path}
            borderColor={data.borderColor}
            bottomSectionBg={data.bottomSectionBg}
        />
    ))

    return (
        <div className="mt-3 overflow-hidden">
            <div className="flex justify-start gap-4 items-center gap-auto">
                {returnData}
            </div>
        </div>
    );
};

export default DashboardDesc;
