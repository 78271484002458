import React from 'react'

const InputButton = ({ label }) => {
    return (
        <div>
            <button type="submit" className="w-full text-white bg-[#23826E] hover:bg-[#0c7e65] bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">{label}</button>
        </div>
    )
}

export default InputButton