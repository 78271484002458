import React from 'react'
import Main from '../InventoryItem/Create/Components/Main';

const CreateInventory = () => {
    const options = [
        'Apple',
        'Banana',
        'Orange',
        // Add more options as needed
    ];
    return (
        <div className='pl-8 pb-4'>

            <Main />
        </div>
    );
};

export default CreateInventory
