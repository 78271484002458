import React from 'react'
import SingleItemDetail from '../InventoryItem/SingleItemDetail/SingleItemDetail'
import SingleItemHero from '../InventoryItem/SingleItemDetail/SingleItemHero'

const SingleInventoryItem = () => {
    return (
        <div className='pl-8 pb-4'>
            <SingleItemHero />

            <SingleItemDetail />
        </div>
    )
}

export default SingleInventoryItem
