import React, { useEffect, useState } from 'react';
import InputText from '../../../../../shared/components/FormInputs/InputText';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../shared/components/FormInputs/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, getProductCategories, addProduct } from '../../../Slices/InventoryAction';

const Form = () => {
    const { register, handleSubmit, setValue } = useForm();
    const dispatch = useDispatch();

    const products = useSelector((state) => state.inventory.products);
    const categories = useSelector((state) => state.inventory.categories);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        dispatch(getAllProducts());
        dispatch(getProductCategories());
    }, [dispatch]);

    const submitHandler = (data) => {
        const productData = {
            ...data,
          
            single_package_price: parseInt(data.single_package_price),
            pharmacyId: "ascdfasdfdsf",
            available_quantity: parseInt(data.available_quantity),
            max_stock_quantity: parseInt(data.max_stock_quantity),
            min_stock_quantity: parseInt(data.min_stock_quantity),
            reorder_point: parseInt(data.reorder_point),
        };

        console.log(productData);
        dispatch(addProduct(productData));
    };

    const handleInputChange = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filteredProducts = products
            .filter((product) => product.name.toLowerCase().startsWith(searchTerm))
            .slice(0, 5);

        setMatches(filteredProducts);
       
        setValue('name', event.target.value);
    };

    const handleSelectionChange = (event) => {
        setValue('name', event.target.value);
    };


    const handleCategoryChange = (event) => {
        const selectedCategoryId = event.target.value;
        setValue('category', selectedCategoryId);
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="grid gap-6 mb-6 md:grid-cols-3">
                <div className='relative'>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                    <input
                        list="browsers"
                        type="search"
                        name="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        
                        onBlur={handleSelectionChange}
                        {...register('name', {
                            onChange: (e) => handleInputChange(e),
                          })}
                      />
                   <datalist id="browsers">
                   {matches.length > 0
                         && matches.map((match) => (
                 <option value={match.name} key={match._id} />
                           
                     
                                   ))}
                </datalist>     
                </div>

                <InputText label='Packaging' requiered name='packaging' placeholder='Packaging...' register={register} defaultValue='pack one' />
                <InputText label='Bar code' requiered name='barcode' placeholder='Barcode...' register={register} />
                <InputText label='Batch Number' name='batch_number' placeholder='Batch Number...' register={register} />
                <InputText label='Available Quantity' type='number' name='available_quantity' placeholder='Available Quantity...' register={register} />
                <InputText label='Reorder Point' type='number' name='reorder_point' placeholder='Reorder Point...' register={register} />
                <InputText label='Minimum Quantity' type='number' name='min_stock_quantity' placeholder='Minimum stock Quantity...' register={register} />
                <InputText label='Maximum Quantity code' type='number' name='max_stock_quantity' placeholder='Maximum stock Quantity...' register={register} />
                <InputText label='Single Price' type='number' name='single_package_price' placeholder='Single Package price...' register={register} />
                <InputText label='Expiration date' requiered type='date' name='expiration_date' placeholder='Expired date...' register={register} />
                <InputText label='Brand ' type='text' requiered name='brand' placeholder='Product Brand' register={register} />
                <InputText label='Supplier' type='text' requiered name='supplier' placeholder='Supplier name' register={register} />
            </div>

            <div className="grid gap-6 mb-6 md:grid-cols-1">
                <div>
                    <label htmlFor="categories" className="block mb-2 text-sm font-medium text-gray-900">Select a category</label>
                    <select
                        id="categories"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={handleCategoryChange}
                    >
                        {categories.length > 0 && categories.map(category => (
                            <option value={category._id} key={category._id}>{category.name}</option>
                        ))}
                    </select>
                </div>
                <input type="hidden" {...register('category')} />
                <TextArea label='Description' name='description' placeholder='Product Description...' register={register} />
            </div>

            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
        </form>
    );
};

export default Form;
