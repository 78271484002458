import React, { useState } from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import { MdAdd } from "react-icons/md";
import { RiArrowDropRightLine } from "react-icons/ri";
import SearchBody from '../../../shared/components/SearchBody/SearchBody';
import TableForm from './TableForm/TableForm';
import { Select, Option, Input } from "@material-tailwind/react"
import { MdSearch } from "react-icons/md";

const ListofMedicineHome = () => {
    const header = 'List of Medicine (298)',
        subHeader = "List of Medicine available for sales shown in DawaFinder";

    const [selectedMedicine, setSelectedMedicine] = useState('');

    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <div className='flex justify-start items-center'>
                        <h1 className='font-bold text-[#1D242E]/50 text-[24px]'>
                            Inventory
                        </h1>

                        <div>
                            <RiArrowDropRightLine size={24} />
                        </div>

                        <SectionHeader header={header} />

                        <h2 className='font-bold text-[#1D242E] text-[24px]'>
                            <span>(298)</span></h2>

                    </div>
                    <SectionSubHeader subHeader={subHeader} />
                </div>
                <div className={` bg-[#F0483E] border text-white rounded p-3 flex justify-between items-center cursor-pointer`} >
                    <div>
                        <MdAdd size={20} fill='#fff' />
                    </div>
                    <h4>Add New Item</h4>
                </div>

            </div>
            <div className='flex justify-between mt-3'>
                <SearchBody />

                <Input label="Input With Icon" icon={<MdSearch />} />


                <div className="flex w-72 flex-col gap-6">
                    <Select color="blue" label="Select Medicine" name='selectedOptions' value={selectedMedicine} onChange={e => selectedMedicine(e.target.value)}>
                        <Option value='html'>1 HTML</Option>
                        <Option value='React'>1 React</Option>
                        <Option value='Vue'>1 Vue</Option>
                        <Option value='ANgular'>1 Angular</Option>
                        <Option value='Svelte'>1 Svelte</Option>
                    </Select>
                </div>


            </div>

            <div>
                <TableForm />
            </div>
        </div>
    )
}

export default ListofMedicineHome
