import React from 'react'
import { MdSearch } from "react-icons/md";

const SearchBody = () => {
    return (
        <div>
            <div className='border border-gray-500 rounded p-4 py-3 min-w-32 w-[340px]'>
                <MdSearch />

            </div>
        </div>
    )
}

export default SearchBody