import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'

const DashboardHero = ({ header, subHeader }) => {
    return (
        <div className='flex flex-col gap-2 mt-4'>
            <SectionHeader header={header} />
            <SectionSubHeader subHeader={subHeader} />
        </div>
    )
}

export default DashboardHero