import React from 'react'
import ListofMedicineHome from '../Components/ListofMedicineHome'

const ListofMedicine = () => {
    return (
        <div className='pl-8 pb-4'>
            <ListofMedicineHome />


        </div>)
}

export default ListofMedicine