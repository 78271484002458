import React from 'react'
import style from './style.module.css'

const TableLoader = () => {
    return (
        <div className="">
            {[...Array(4)].map((_, index) => (
                <div className={style.card}>
                    <div className="flex justify-between gap-12">

                        <div className={`flex gap-4 basis-1/5 h-8`}>

                            <p className={`${style.skeleton} ${style.skeleton_text} h-8 basis-4/5`}></p>
                        </div>

                        <p className={`${style.skeleton} basis-1/6 h-8 rounded-lg`}></p>
                        <p className={`${style.skeleton} basis-1/6 h-8 rounded-lg`}></p>
                        <p className={`${style.skeleton} basis-1/6 h-8 rounded-lg`}></p>
                        <p className={`${style.skeleton} basis-1/6 h-8 rounded-lg`}></p>





                    </div>
                </div>
            ))}


        </div>

    )
}

export default TableLoader