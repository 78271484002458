import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchMedicine from "./SearchMedicine";
import { BsFillTriangleFill } from "react-icons/bs";
import { getAllMedicineFormularies } from "../Slices/FormularyAction";

const NationalMedicineFormularyHome = () => {
  const [show, setShow] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const dispatch = useDispatch();

  const { medicineFormularies, medicineCategories } = useSelector(
    (state) => state.formulary
  );

  useEffect(() => {
    dispatch(getAllMedicineFormularies());
  }, [dispatch]);

  useEffect(() => {
    console.log("Medicine Formularies:", medicineFormularies);
  }, [medicineFormularies]);

  useEffect(() => {
    setShow(inputSearch.length > 0);
  }, [inputSearch]);

  // Filter the medicine formularies based on the input search term
  const filteredFormularies = medicineFormularies?.filter(
    (formulary) => formulary.title && formulary.title.toLowerCase().includes(inputSearch.toLowerCase())
  ) || [];

  return (
    <div className="flex gap-6 mt-3">
      {/* Search medicine here */}
      <div className="basis-auto lg:basis-1/2 flex flex-col justify-start gap-6 relative">
        <SearchMedicine input={inputSearch} method={setInputSearch} />

        {show && filteredFormularies.length > 0 && (
          <div className="absolute top-16 w-full">
            <div className="flex flex-col gap-0 relative">
              <div className="absolute -top-6 left-12">
                <BsFillTriangleFill color="white" size={32} />
              </div>

              <div className="bg-white h-full rounded-[5px] p-6">
                {filteredFormularies.map((formulary) => (
                  <div key={formulary.id}>
                    <p>
                      <span className="font-bold">{formulary.name}</span>
                      {formulary.description}
                    </p>
                    <p>{formulary.details}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="border border-[#2A363B78] p-3 py-8 md:px-8 flex flex-col items-center justify-center rounded">
          <p className="text-[#2A363B7D]/50 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis,
            assumenda quo consequuntur placeat ratione, maiores sapiente quos
            odio soluta quaerat quibusdam tempore. Impedit enim harum corporis
            alias. Aperiam, non porro.
          </p>
        </div>
        <div className="border border-[#2A363B78] p-3 flex flex-col items-center justify-center rounded text-[#2A363B]">
          <h3>Recently Released Medicine</h3>
          <ul className="list-disc">
            {medicineCategories?.map((category) => (
              <li key={category.id}>{category.name}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="basis-1/2 flex flex-col justify-start gap-6">
        <div className="border border-[#2A363B78] p-3 py-8 md:px-8 flex flex-col items-center justify-center gap-2 rounded">
          <h3>Ethiopian Medicine Formulary</h3>
          <p className="text-[#2A363B7D]/50 text-sm">
            You have to check before order the medicine
          </p>
        </div>

        <div className="border border-[#2A363B78] p-3 py-8 md:px-8 flex flex-col items-center gap-2 justify-center rounded">
          <h3 className="text-[#2A363B] text-lg">
            Ethiopian Medicine Formulary
          </h3>
          <p className="text-[#2A363B7D]/50 text-sm">
            You have to check before order the medicine
          </p>
          <ul className="list-disc pl-[28px] text-[#2A363B] text-base">
            <li>liquids that are swallowed.</li>
            <li>drops that are put into ears or eyes.</li>
            <li>creams, gels, or ointments that are rubbed onto the skin.</li>
            <li>patches that are stuck to skin (called transdermal patches)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NationalMedicineFormularyHome;
