import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import { MdAdd } from "react-icons/md";
import { RiArrowDropRightLine } from "react-icons/ri";
import SearchBody from '../../../shared/components/SearchBody/SearchBody';
import MedicineGroupTable from './MedicineGroupTable/MedicineGroupTable';
const MedicineGroupsHome = () => {
    const header = 'Medicine Groups (8)',
        subHeader = "List of Medicine groups"
    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <div className='flex justify-start items-center'>
                        <h1 className='font-bold text-[#1D242E]/50 text-[24px]'>
                            Inventory
                        </h1>

                        <div>
                            <RiArrowDropRightLine size={24} />
                        </div>

                        <SectionHeader header={header} />



                    </div>
                    <SectionSubHeader subHeader={subHeader} />
                </div>
                <div className={` bg-[#F0483E] border text-white rounded p-3 flex justify-between items-center cursor-pointer`} >
                    <div>
                        <MdAdd size={20} fill='#fff' />
                    </div>
                    <h4>Add New Item</h4>
                </div>

            </div>
            <div className='flex justify-between mt-3'>
                <SearchBody />

                <div className={` bg-[#fff] border border-[#1D242E]/80  rounded p-2 flex justify-between text-sm items-center cursor-pointer px-8`} >

                    <h4>Select group</h4>
                </div>

            </div>

            <div>
                <MedicineGroupTable />
            </div>


        </div>
    )
}

export default MedicineGroupsHome
