import React from 'react'
import SectionHeader from '../../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../../shared/components/SectionBody/SectionSubHeader'
import { Link } from 'react-router-dom'
import Table from './Table'

const NewAddedInventories = () => {
    return (
        <div>
            <div className='flex justify-between gap-2 mt-4 mb-4'>
                <SectionHeader header='Newly added Inventories' />
                <Link href="#" class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center  text-blue-700 rounded-lg  outline-none">
                    See All
                    <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </Link>
            </div>
            <Table />
        </div>
    )
}

export default NewAddedInventories
