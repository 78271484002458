import React from 'react'
import CardBody from './CardBody'

const Card = ({ header, children }) => {
    return (
        <div class="w-full bg-white  rounded border  border-opacity-30">

            <div className='flex justify-between items-center border-b border-gray-800/30'>

                <h2 className=' py-5'><span className='px-4'>{header}</span></h2>

                <div className='pr-4'>
                    Edit
                </div>

            </div>


            <div className='flex justify-between p-4'>
                <CardBody value='Rehobot Pharmacy' label='Pharmacy Name' />
                <CardBody value='PH349TY228 ' label='Pharmacy ID' />

            </div>
        </div>

    )
}

export default Card