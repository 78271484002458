import axios from "axios";
import { API_BASE_URL } from "../utils/Constants";
const api = axios.create({
  baseURL: API_BASE_URL,
});

export const setupApiInterceptors = (store) => {
  api.interceptors.request.use(
    async (config) => {
      // Safely access the access token
      // const accessToken = store.getState().auth.access;

      // if (accessToken) {
      //   config.headers.Authorization = `Bearer ${accessToken}`;
      // }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      console.log("")
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
       
      }
      console.error("API Response Error:", error);
      return Promise.reject(error);
    }
  );
};

export default api;
