import React from 'react'
import ConfigurationHome from '../Components/ConfigurationHome'

const ConfiguratonPage = () => {
    return (
        <div className='pl-8 pb-4'>
            <ConfigurationHome />

        </div>
    )
}

export default ConfiguratonPage