import React from 'react'

const DateNotifier = () => {
    return (
        <div className="hidden md:flex  flex-col justify-center gap-1 px-4">
            <div className="flex gap-x-2 ms-9">
                <div class="w-[18px] h-[18px] bg-yellow-400 rounded-full"></div>
                <div class="text-right text-gray-800 text-sm font-medium font-['Poppins'] leading-normal">
                    Good Morning
                </div>
            </div>
            <div className="flex gap-x-2 px-2">
                <div class="text-gray-800 text-xs w-[96px] h-[18px] whitespace-nowrap font-normal font-['Poppins'] justify-start">
                    {/* {Date.now().toString()} */}
                    14 January 2024
                </div>
                <div class="text-gray-800 w-[50px] h-[18px] text-xs font-normal font-['Poppins']">
                    22:45:04
                </div>
            </div>
        </div>
    )
}

export default DateNotifier