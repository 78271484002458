import React from "react";
import Card from "../../../shared/components/Cards/Card";
import { TiBatteryLow } from "react-icons/ti";

import { MdOutlineReceiptLong, MdOutlineWarningAmber } from "react-icons/md";
const InventoryDesc = () => {
    return (
        <div className="mt-3 overflow-hidden">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-start gap-4 items-center gap-auto">

                <Card
                    icon={<MdOutlineReceiptLong color="#FED600" size={37} />}
                    header="3000"
                    subHeader="Total Inventory"
                    path="/inventory-status"
                    borderColor="border-[#FED600]"
                    bottomSectionBg="bg-[#FED600]"
                />{" "}
                <Card
                    icon={<TiBatteryLow color="#03A9F5" size={37} />}
                    header="298"
                    subHeader="Low stocks Products"
                    path="/inventory-status"
                    borderColor="border-[#03A9F5]"
                    bottomSectionBg="bg-[#03A9F5]"
                />{" "}
                <Card
                    icon={<MdOutlineWarningAmber color="#F0483E" size={37} />}
                    header="20"
                    subHeader="Expired Items"
                    path="/inventory-status"
                    borderColor="border-[#F0483E]"
                    bottomSectionBg="bg-[#F0483E]"
                />
            </div>
        </div>
    );
};

export default InventoryDesc;
