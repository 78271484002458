import React from 'react'
import ActionButton from './ActionButton'

const TableBody = () => {
    return (
        <tbody>

            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Snails running low stocks
                </th>
                <td className="px-6 py-4">
                    Skin care
                </td>
                <td className="px-6 py-4">
                    22
                </td>
                <td className="px-6 py-4">
                    400  ETB
                </td>
                <td className="px-6 py-4">
                    <ActionButton />




                </td>
            </tr>

            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Snails running low stocks
                </th>
                <td className="px-6 py-4">
                    Skin care
                </td>
                <td className="px-6 py-4">
                    22
                </td>
                <td className="px-6 py-4">
                    400  ETB
                </td>
                <td className="px-6 py-4">
                    <ActionButton />




                </td>
            </tr>

            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Snails running low stocks
                </th>
                <td className="px-6 py-4">
                    Skin care
                </td>
                <td className="px-6 py-4">
                    22
                </td>
                <td className="px-6 py-4">
                    400  ETB
                </td>
                <td className="px-6 py-4">
                    <ActionButton />




                </td>
            </tr>



        </tbody>
    )
}

export default TableBody