import React from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";

const LanguageDrop = ({ langDrop, language, handleChangeLang, handleLang }) => {
    const handleClick = (lang) => {
        return handleChangeLang(lang)
    }

    const handleLangClick = () => {
        return handleLang();
    }

    return (
        <>
            {/* Back drop  */}
            {langDrop && <div className="fixed z-40 top-0 left-0 w-screen h-screen bg-black opacity-10" onClick={handleLangClick}></div>}

            {/* Language lists */}
            {langDrop &&
                <ul className="fixed w-48  top-14 p-4 right-[25%] bg-gray-50 rounded shadow flex flex-col gap-3 z-50 translation-all duration-500 text-base">
                    <li className={`hover:text-green-500  flex items-center justify-between ${language.includes('Afan') && 'text-green-500 border-b border-green-500'} `} onClick={e => handleClick('Afan Oromo')}>
                        Afan Oromo
                        <span>
                            <RiArrowDropDownLine />
                        </span>
                    </li>
                    <li className={`hover:text-green-500  flex items-center justify-between ${language.includes('Amha') && 'text-green-500 border-b border-green-500'} `} onClick={e => handleClick('Amharic')}>

                        Amharic


                        <RiArrowDropDownLine />

                    </li>
                    <li className={`hover:text-green-500  flex items-center justify-between ${language.includes('Engl') && 'text-green-500 border-b border-green-500'} `} onClick={e => handleClick('English (US)')}>

                        English (US)
                        <RiArrowDropDownLine />

                    </li>
                </ul>
            }
        </>
    )
}

export default LanguageDrop