import React from 'react'
import DateRange from './DateRange'
import MedicineGroup from './MedicineGroup'

const SalesReportCard = () => {
    return (
        <div className='mt-4'>
            <div className='grid grid-cols-3 gap-6 justify-between items-center'>

                <DateRange />
                <MedicineGroup />


                <div>
                    <h3>Pharmacy</h3>

                    <div class=" bg-slate-200/80 rounded border border-gray-800/30">

                        <p class="px-3 py-2 text-gray-800 text-sm font-normal font-poppins">Rehobot Pharmacy</p>

                    </div>
                </div>



            </div>
        </div>

    )
}

export default SalesReportCard