import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputText from "../../../shared/components/FormInputs/InputText";
import InputButton from "../../../shared/components/FormInputs/InputButton";
import { register as registerOrg } from "../Slices/authSlice";

const steps = [
  "Organization Details",
  "Location & License",
  "Contact & Images",
];

const RegisterForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.registerError);
  const [images, setImages] = useState([]);
  const [step, setStep] = useState(1);

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  const onSubmit = async (data) => {
    try {
      const imageUrls = images.map((image) => URL.createObjectURL(image));

      const {
        coordinates,
        street,
        country,
        city,
        phoneNumber,
        email,
        serviceName,
        serviceDescription,
        ...rest
      } = data;

      const formattedData = {
        ...rest,
        images: imageUrls,
        absoluteLocation: { coordinates, street },
        relativeLocation: { country, city, street },
        contact: { phoneNumber, email },
        service: [{ serviceName, serviceDescription }],
      };

      const res = await dispatch(registerOrg(formattedData)).unwrap();
      console.log(res);
      navigate("/");

      reset();
      setImages([]);
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  const errorMessage = error
    ? typeof error === "string"
      ? error
      : JSON.stringify(error)
    : null;

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full p-6 space-y-6 bg-white shadow-lg rounded-xl "
    >
     <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-3xl ">
     Welcome to Dawa Finder ERP👋
                </h1>
           
                <h1 className="text-sm font-normal tracking-tight text-center text-gray-900 md:text-xl ">
                Kindly fill in your details below to register your pharmacy
                </h1>

      {/* Stepper */}
      <div className="flex items-center justify-between mb-6" >
        {steps.map((label, index) => (
          <div key={index} className="flex items-center">
            <div
              className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${
                step === index + 1
                  ? "bg-[#83a8a0]"
                  : step > index + 1
                  ? "bg-[#23826E]"
                  : "bg-gray-300"
              }`}
            >
              {step > index + 1 ? "✓" : index + 1}
            </div>
            <div
              className={`ml-2 text-sm ${
                step === index + 1 ? "text-blue-500" : "text-gray-500"
              }`}
            >
              {label}
            </div>
            {index < steps.length - 1 && (
              <div
                className={`flex-1 h-0.5 mx-2 ${
                  step > index + 1 ? "bg-green-500" : "bg-gray-300"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>

      {/* Step 1: Organization Details */}
      {step === 1 && (
        <div className="space-y-4">
          <InputText
            name="name"
            label="Organization Name"
            type="text"
            placeholder="Enter name"
            register={register}
          />
          <InputText
            name="subscription"
            label="Subscription"
            type="text"
            placeholder="Enter subscription"
            register={register}
          />
          <InputText
            name="description"
            label="Description"
            type="text"
            placeholder="Enter description"
            register={register}
          />
        </div>
      )}

      {/* Step 2: Location & License */}
      {step === 2 && (
        <div className="space-y-4">
          <InputText
            name="city"
            label="City"
            type="text"
            placeholder="Enter city"
            register={register}
          />
          <InputText
            name="country"
            label="Country"
            type="text"
            placeholder="Enter country"
            register={register}
          />
          <InputText
            name="street"
            label="Street"
            type="text"
            placeholder="Enter street"
            register={register}
          />
          <InputText
            name="license"
            label="License Number"
            type="text"
            placeholder="Enter license number"
            register={register}
          />
          <InputText
            name="type"
            label="Organization Type"
            type="text"
            placeholder="Enter organization type"
            register={register}
          />
        </div>
      )}

      {/* Step 3: Contact & Images */}
      {step === 3 && (
        <div className="space-y-4">
          <InputText
            name="phoneNumber"
            label="Phone Number"
            type="text"
            placeholder="Enter phone number"
            register={register}
          />
          <InputText
            name="email"
            label="Email"
            type="email"
            placeholder="Enter email"
            register={register}
          />
          {/* <InputText
            name="serviceName"
            label="Service Name"
            type="text"
            placeholder="Enter service name"
            register={register}
          />
          <InputText
            name="serviceDescription"
            label="Service Description"
            type="text"
            placeholder="Enter service description"
            register={register}
          /> */}
          <label className="block">
            <span className="text-gray-700">Upload Images</span>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
            />
          </label>
        </div>
      )}

      {errorMessage && (
        <p className="text-center text-red-500">{errorMessage.message}</p>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between pt-4">
        {step > 1 && (
          <button
            type="button"
            onClick={prevStep}
            className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
          >
            Previous
          </button>
        )}
        {step < steps.length ? (
          <button
            type="button"
            onClick={nextStep}
            className="px-4 py-2 ml-auto text-white bg-[#23826E] rounded-lg hover:bg-[#497e73]"
          >
            Next
          </button>
        ) : (
          <InputButton label="Register" disabled={loading} />
        )}
      </div>
    </form>
  );
};

export default RegisterForm;
