import { createSlice } from "@reduxjs/toolkit";
import { SubcribeToPremiumPlan } from './subscriptionActions';

const initialState = {
  loading: false,
  error: null,
  subscription: null,
  isPremium:JSON.parse(localStorage.getItem("isPremium")) || false,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(SubcribeToPremiumPlan.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(SubcribeToPremiumPlan.fulfilled, (state,  action ) => {

      state.loading = false;
      state.error = null;
      state.subscription = action.payload;
      state.isPremium = true;
      localStorage.setItem('isPremium', true);
      if( action.payload.paymentUrl){

        const url=action.payload.paymentUrl.data.checkout_url;
        window.location.href = url;
      }
    });
    builder.addCase(SubcribeToPremiumPlan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default subscriptionSlice.reducer;
