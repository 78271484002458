import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({ icon, header, subHeader, path, borderColor, bottomSectionBg , bgColor}) => {
    return (
        <div className={` border ${borderColor} w-[200px] sm:w-full rounded-lg ${bottomSectionBg} ${bgColor}  overflow-hidden   `}>

            <div className={`flex justify-center items-center flex-col py-2 bg-white rounded-lg  ${borderColor} gap-1 p-8 `}>

                {/* icon */}
                <div className='pt-1'>
                    {icon}
                </div>
                <div>
                    <h1 className='text-lg font-poppins text-[#1D242E] font-bold '>
                        {header}

                    </h1>
                </div>

                <div>
                    <h3 className='text-sm'>
                        {subHeader}

                    </h3>
                </div>


            </div>
            <div className={`${bottomSectionBg} text-white rounded-lg px-4 text-xs py-2 `}>
                <Link to={path}>

                    <p>View Detailed Report</p>
                </Link>
            </div>

        </div>
    )
}

export default Card