import { createSlice } from "@reduxjs/toolkit";
import {  getAllProducts, getProductCategories } from "./InventoryAction.js";

const initialState = {
  loading: false,
  loadingAllProducts: false,
  loadingProduct: false,
  categories:[],
  loadingCategories:[],
  error: null,
  inventories: [],
  success: true,
  products:[],
  product:[],
  myproducts:[],
  message: "",
  singleInventory: [],
};

const InventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.pending, (state) => {
      state.loadingAllProducts = true;
      state.error = null;
    });
    builder.addCase(getAllProducts.fulfilled, (state,  action ) => {
      state.loadingAllProducts = false;
      state.error = null;
      state.products = action.payload;
    });
    builder.addCase(getAllProducts.rejected, (state, action) => {
      state.loadingAllProducts = false;
      state.error = action.error.message;
    });
    builder.addCase(getProductCategories.pending, (state) => {
      state.loadingCategories = true;
      state.error = null;
    });
    builder.addCase(getProductCategories.fulfilled, (state,  action ) => {

      state.loadingCategories = false;
      state.error = null;
      state.categories = action.payload;
    });
    builder.addCase(getProductCategories.rejected, (state, action) => {
      state.loadingCategories = false;
      state.error = action.error.message;
    });
  },
});

export default InventorySlice.reducer;
