import React from 'react'
import InventoryHome from '../Components/InventoryHome'

const Inventory = () => {
    return (
        <div className='pl-8 pb-4'>

            <InventoryHome />

        </div>
    )
}

export default Inventory