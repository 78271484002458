import React from 'react'

const SalesMade = () => {
    return (
        <div className='basis-3/4'>

        <div class=" bg-white rounded border border-gray-800 mt-4">
            <div className='border-b border-gray-800/30'>

                <h3 class="pl-6 py-2 text-slate-800 text-[15px] font-semibold font-poppins tracking-tight">Sales Made</h3>
            </div>
            s

        </div>
        </div>

    )
}

export default SalesMade