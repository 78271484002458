import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

import {thunk} from 'redux-thunk';
import { setupApiInterceptors } from "../services/apiService";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk,
    serializableCheck: true,
  }),
});


setupApiInterceptors(store);

export default store;