import React from 'react'
import ReportHome from '../Components/ReportHome'

const Report = () => {
    return (
        <div className='pl-8 pb-4'>
            <ReportHome />
        </div>
    )
}

export default Report