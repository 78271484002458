import React from 'react'
import Card from './Card'

const ConfigureCards = () => {
    return (
        <div>
            <div className='grid grid-cols-2 gap-6'>



                <Card header='Branding' />
                <Card header='Owner' />

            </div>
        </div>

    )
}

export default ConfigureCards