import React from 'react'

const SectionSubHeader = ({ subHeader }) => {
    return (
        <div>
            <h5 className=' text-sm text-[#1D242E]'>
                {subHeader}
            </h5>
        </div>
    )
}

export default SectionSubHeader