import React from 'react'

const AuthWrapper = ({ children }) => {
    return (
        <section className="bg-inherit " style={{marginLeft:'-250px'}}>
            <div className="flex flex-col items-center justify-center px-2 py-8 mx-auto lg:px-6 md:h-screen lg:py-0">

                <div className="w-full text-white rounded-lg shadow-xl dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-100 dark:border-gray-100">

                    {children}
                </div>
            </div>
        </section>
    )
}

export default AuthWrapper