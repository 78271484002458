import React from "react";
import { FaLessThan, FaGreaterThan } from "react-icons/fa6";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const TableForm = () => {

    const tableData = [
        {
            name: 'Augmentin 625 Duo Tablet',
            medicineId: 'D06ID232435454',
            groupName: 'Generic Medicine',
            stackInQty: '350',
            slug: '/hello'
        },
        {
            name: 'Augmentin 625 Duo Tablet',
            medicineId: 'D06ID232435454',
            groupName: 'Generic Medicine',
            stackInQty: '350',
            slug: '/hello'


        },
        {
            name: 'Augmentin 625 Duo Tablet',
            medicineId: 'D06ID232435454',
            groupName: 'Generic Medicine',
            stackInQty: '350',
            slug: '/hello'


        },
        {
            name: 'Augmentin 625 Duo Tablet',
            medicineId: 'D06ID232435454',
            groupName: 'Generic Medicine',
            stackInQty: '350',
            slug: '/hello'


        },
    ]
    return (
        <div className="mt-3">
            <div className="relative overflow-x-auto border border-[#1D242E4D]/20 rounded">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Medicine name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Medicine ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Group Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Stack in QTY
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {tableData.map(table => (
                            <tr className="bg-white border-b ">
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                >
                                    {table.name}
                                </th>
                                <td className="px-6 py-4">{table.medicineId}</td>
                                <td className="px-6 py-4">{table.groupName}</td>
                                <td className="px-6 py-4">{table.stackInQty}</td>
                                <td className="px-6 py-4"> <Link to={`/list-of-medicine/${table.slug}`}> View full details</Link>  </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className="flex flex-col justify-between md:flex-row mt-3 text-sm">
                <h5>
                    Showing 1-8 results of <span>298</span>
                </h5>

                <div className="flex justify-start items-center gap-2">
                    <button className="border bg-white border-[#1D242E4D]/20 p-2 rounded">
                        <FaLessThan color="gray" />
                    </button>

                    <div>
                        <h5 className="flex items-center">
                            Pages <span>01</span>
                            <span>
                                <RiArrowDropDownLine />{" "}
                            </span>
                        </h5>
                    </div>

                    <button className="border bg-white border-[#1D242E4D]/20 p-2 rounded">
                        <FaGreaterThan />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TableForm;
