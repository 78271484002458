import React from 'react'

const SectionHeader = ({ header }) => {
  return (
    <div>
      <h1 className='font-bold text-[#1D242E] text-[24px]'>
        {header}
      </h1>
    </div>
  )
}

export default SectionHeader