import React from 'react'
import { Link } from 'react-router-dom';

const MedicineGroupTable = () => {
    return (


        <div className="mt-3 basis-1/2">
            <div className="relative overflow-x-auto border border-[#1D242E4D]/20 rounded">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b border-gray-800/30 ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Group Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                No of Medicine
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white ">
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                2485855848577
                            </th>
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                01 Dec 2021  10:25

                            </th>
                            <td className="px-6 py-4"> <Link to='/medicine-group/44'> View full details</Link>  </td>

                        </tr>
                        <tr className="bg-white ">
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                2485855848577
                            </th>
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                01 Dec 2021  10:25

                            </th>

                            <td className="px-6 py-4"> <Link to='/medicine-group/44'> View full details</Link>  </td>

                        </tr>

                        <tr className="bg-white  ">
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                2485855848577
                            </th>
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                            >
                                01 Dec 2021  10:25
                            </th>
                            <td className="px-6 py-4"> <Link to='/medicine-group/44'> View full details</Link>  </td>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );
};



export default MedicineGroupTable