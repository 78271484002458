import { createSlice } from "@reduxjs/toolkit";
import {
  getAllMedicineFormularies,
  getMedicineCategories,
  addMedicineFormulary,
} from "./FormularyAction.js";

const initialState = {
  loadingMedicineFormularies: false,
  loadingMedicineCategories: false,
  medicineFormularies: [],
  medicineCategories: [],
  error: null,
};

const FormularySlice = createSlice({
  name: "formulary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMedicineFormularies.pending, (state) => {
        state.loadingMedicineFormularies = true;
        state.error = null;
      })
      .addCase(getAllMedicineFormularies.fulfilled, (state, action) => {
        console.log(action.payload)
        state.loadingMedicineFormularies = false;
        state.medicineFormularies = action.payload;
      })
      .addCase(getAllMedicineFormularies.rejected, (state, action) => {
        state.loadingMedicineFormularies = false;
        state.error = action.payload
          ? action.payload
          : "Failed to fetch medicine formularies";
      })
      .addCase(getMedicineCategories.pending, (state) => {
        state.loadingMedicineCategories = true;
        state.error = null;
      })
      .addCase(getMedicineCategories.fulfilled, (state, action) => {
        state.loadingMedicineCategories = false;
        state.medicineCategories = action.payload;
      })
      .addCase(getMedicineCategories.rejected, (state, action) => {
        state.loadingMedicineCategories = false;
        state.error = action.payload
          ? action.payload
          : "Failed to fetch medicine categories";
      })
      .addCase(addMedicineFormulary.fulfilled, (state, action) => {
        state.medicineFormularies.push(action.payload);
      })
      .addCase(addMedicineFormulary.rejected, (state, action) => {
        state.error = action.payload
          ? action.payload
          : "Failed to add medicine formulary";
      });
  },
});

export default FormularySlice.reducer;
