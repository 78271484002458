import React, { useState } from "react";
import { MdInventory } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useLocation } from 'react-router-dom'

const SidebarSectionList = ({ value, sectionList, path }) => {
    const location = useLocation()
    const [show, setShow] = useState(false)

    const handleShow = () => {
        setShow(prev => !prev)
    }
    return (
        <div className={`gap-1 flex flex-col ${show ? 'bg-[#539284]' : ''}`}>
            <Link to={path} className="flex justify-between items-center pr-4 hover:bg-[#539284] " onClick={handleShow}>
                <div className="pl-4 py-[13px]">
                    <div >
                        <div className="flex items-center justify-start gap-2 text-white ">
                            <span className='text-2xl md:text-lg'>
                                <MdInventory />
                            </span>
                            <span className="ml-2 md:ml-0">{value}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <RiArrowDropDownLine color="white" size={24} />
                </div>
            </Link>

            {show &&
                <div className='ml-2 md:ml-0'>
                    {sectionList?.length > 0 && sectionList.map(section => (
                        <Link to={section.path} className={`flex justify-start items-center text-white gap-2 py-[13px] ${location.pathname === section.path ? 'bg-[#23826E]' : ''}   hover:bg-[#23826E] pl-12`}>
                            {section.value}
                        </Link>
                    ))}




                </div>
            }

        </div>
    );
};

export default SidebarSectionList;
