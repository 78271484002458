import React from 'react'
import TableBody from './TableBody'
import TableHeader from './TableHeader'

const Table = () => {
    return (


        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <TableHeader />
                <TableBody />

            </table>
        </div>

    )
}

export default Table