import React from 'react'
import CardBody from './CardBody'

const SingleListCard = () => {
    return (
        <div class="w-full bg-white  rounded border  border-opacity-30">

            <h2 className='border-b border-gray-800/30 py-5'><span className='px-4'>Medicine</span></h2>

            <div className='flex justify-between p-4'>

                <CardBody value={298} label='Medicine ID' />
                <CardBody value={298} label='Medicine ID' />


            </div>



        </div>
    )
}

export default SingleListCard