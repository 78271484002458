import React from 'react'
import AuthWrapper from '../../../shared/components/FormInputs/AuthWrapper'
import LoginForm from './LoginForm'

const Main = () => {
    return (
        <AuthWrapper>
            <div className="p-6 space-y-3 md:space-y-3 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-3xl ">
            Welcome Back 👋
                </h1>
           
                <h1 className="text-sm font-normal tracking-tight text-center text-gray-900 md:text-xl ">
                Please login to your account to continue
                </h1>
                <LoginForm />
            </div>
        </AuthWrapper>
    )
}

export default Main