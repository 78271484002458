import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import api from "../../../services/apiService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    access: Cookies.get("accessToken") || null,
    resetSuccessfull: null,
    profile: null,
    error: null,
    forgetError: null,
    forgotMessage: null,
    isAuthenticated:
      JSON.parse(localStorage.getItem("isAuthenticated")) || false,
  },

  reducers: {
    clearAuthentication: (state) => {
      state.isAuthenticated = false;
      state.access = null;
      localStorage.setItem("isAuthenticated", false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.registerError = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.newUser;
        state.isAuthenticated = true;
        localStorage.setItem("isAuthenticated", true);
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.registerError = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.access = action.payload.token;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        localStorage.setItem("isAuthenticated", true);
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const setAccessTokenInCookie = (accessToken) => {
  // Set the access token in an HTTP-only cookie
  Cookies.set("accessToken", accessToken, { secure: true, sameSite: "strict" });
};

export const register = createAsyncThunk(
  "auth/register",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        "userapi/health-organizations/",
        userData
      );
      setAccessTokenInCookie(response.data.data.token);

      console.log(response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export default authSlice.reducer;

export const login = createAsyncThunk(
  "auth/login",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        "userapi/health-organizations/",
        userData
      );

      setAccessTokenInCookie(response.data.data.token);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = () => (dispatch) => {
  Cookies.remove("accessToken");
  dispatch(authSlice.actions.clearAuthentication());
};

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`userapi/health-organizations/`);
      console.log(response.data);
      return response.data.profile;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
