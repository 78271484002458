
import { createAsyncThunk } from "@reduxjs/toolkit";

// import { endPoint } from "../../../assets/JS/api.js";

import api from "../../../services/apiService";


export const SubcribeToPremiumPlan = createAsyncThunk('inventory/addProduct', async (pharmacyData, { dispatch, rejectWithValue }) => {
  try {
    
    const response = await api.post('/userapi/health-organization-subscriptions/', pharmacyData);
   
     console.log(response.data)
    //  const url=response.data.data.paymentUrl.data.checkout_url;
    //  window.location.href = url;
     return response.data.data;

    
  } catch (error) {
   console.log(error)
    return rejectWithValue(error.response.data);
  }
});




