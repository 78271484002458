import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams, getRowId, GridActionsCellItem } from '@mui/x-data-grid';
import { TbListDetails } from "react-icons/tb";
import { MdModeEdit, MdOutlineDelete } from "react-icons/md";
import { GetAllProducts } from '../../../../../assets/JS/Inventory/Category/Product';
import ActionButton from './ActionButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts  } from '../../../Slices/InventoryAction'
import TableLoader from './TableLoader/TableLoader.jsx';


export default function DataGridComponent({ rows }) {
    const dispatch = useDispatch()
    const products = useSelector((state) => state.inventory.products);
    const loading = useSelector((state) => state.inventory.loadingAllProducts);

    useEffect(() => {
        dispatch(getAllProducts());
    }, [dispatch]);

    const [dataRows, setDataRows] = useState([]); //Contain product data from APi Call that fills as the row of the data grid

    useEffect(() => {
        if (products.length > 0) {
            const rows = products.map((product, index) => {
                return {
                    id: index, category: product.category, productName: product.name, brand: product.brand, expiration_date: product.expiration_date,
                    price: product.single_package_price, productId: product._id,
                    productData: product
                }
            });
            setDataRows(rows);
        }
    }, [products]);
    //Calling hooks
    const navigate = useNavigate()

    // Action on the data grid table starts from here
    /**
     * 
     * @param {*} productId 
     * @description Id of the product to be deleted
     * @type string
     */
    const handleDelete = (productId) => {
        console.log('Delete clicked')
        console.log(productId)

    }

    /**
     * 
     * @param {*} productData 
     * @description product data on the row to be edited
     * @returns array of product
     * @type Array
     */
    const handleEdit = (productData) => {
        console.log('Edit button clicked')
        console.log(productData)
        navigate('/inventory/add-item', { data: productData })

    }

    /**
   * 
   * @param {*} productData 
   * @description product data on the row to be displayed in detail
   * @returns array of product
   * @type Array
   */
    const handleView = (productData) => {
        console.log('View button clicked')
        console.log(productData)
        navigate('/inventory/product/detail/' + productData.id, { state: productData })



    }
    const method = [handleView, handleEdit, handleDelete] //I used this method to pass all three methods as Prop to ActionButton components

    //@end Action on the data grid table ended here

    //Columns to be showed on the data grid
    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'productName',
            headerName: 'Product name',
            width: 150,
            editable: true,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 150,
            editable: true,
        },
        {
            field: 'price',
            headerName: 'Single Price',
            type: 'number',
            width: 100,
            editable: true,
        },
        {
            field: 'brand',
            headerName: 'Brand',
            type: 'number',
            width: 70,
            editable: true,
        },
        {
            field: 'expiration_date',
            headerName: 'EXP Date',
            type: 'number',
            width: 70,
            editable: true,
        },

        {
            field: 'status',
            headerName: 'Status',
            type: 'number',
            width: 60,
            editable: true,
        },


        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 300,
            cellClassName: 'actions',
            getActions: (params) => {
                const { productId, productData } = params.row
                return [
                    <GridActionsCellItem
                        icon={<ActionButton method={method} data={productData} />}
                        // icon={<MdOutlineDelete size={22} color='red' />}
                        label="Delete"
                        color="inherit"
                    />,
                ];
            },
        },


    ];


  
       
    

    //the hooks that helped me to call API method
 


    return (
        <Box sx={{ height: 500, width: '100%', backgroundColor: 'white', borderTopStyle: 'solid' }}>

            {loading ? <TableLoader /> :
                <DataGrid
                    rows={dataRows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            }
        </Box>
    );
}