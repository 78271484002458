import React from 'react'
import SalesReportHome from '../Components/SalesReportHome'

const SalesReportPage = () => {
    return (
        <div className='pl-8 pb-4'>

            <SalesReportHome />
        </div>
    )
}

export default SalesReportPage