import React from 'react'
import SingleMedicineGroupsHome from '../Components/SingleMedicineGroupsHome'

const SingleMedicineGroupsPage = () => {
    return (
        <div className='pl-8 pb-4'>

            <SingleMedicineGroupsHome />
        </div>
    )
}

export default SingleMedicineGroupsPage