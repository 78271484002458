import React from "react";
import Card from "../../../shared/components/Cards/Card";
import { AiOutlineMedicineBox } from "react-icons/ai";

import { MdOutlineHealthAndSafety, MdOutlineReceiptLong, MdOutlineWarningAmber } from "react-icons/md";
const ReportDesc = () => {
    return (
        <div className="mt-3 overflow-hidden">
            <div className="flex justify-start gap-4 items-center gap-auto">
                <Card
                    icon={<MdOutlineHealthAndSafety color="#01A768" size={37} />}
                    header="Good"
                    subHeader="Inventory Status"
                    path="/inventory-status"
                    borderColor="border-[#01A768]"
                    bottomSectionBg="bg-[#01A768]"
                />
                <Card
                    icon={<MdOutlineReceiptLong color="#FED600" size={37} />}
                    header="ETB 30,509"
                    subHeader="Revenue: Aug, 2023"
                    path="/inventory-status"
                    borderColor="border-[#FED600]"
                    bottomSectionBg="bg-[#FED600]"
                />{" "}
                <Card
                    icon={<AiOutlineMedicineBox color="#03A9F5" size={37} />}
                    header="298"
                    subHeader="Medicine Available"
                    path="/inventory-status"
                    borderColor="border-[#03A9F5]"
                    bottomSectionBg="bg-[#03A9F5]"
                />{" "}
                <Card
                    icon={<MdOutlineWarningAmber color="#F0483E" size={37} />}
                    header="20"
                    subHeader="Medicine Shortage"
                    path="/inventory-status"
                    borderColor="border-[#F0483E]"
                    bottomSectionBg="bg-[#F0483E]"
                />
            </div>
        </div>
    );
};

export default ReportDesc;
