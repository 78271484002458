import axios from "axios";
import { endPoint } from "../../api.js";

export async function CreateProduct(postData) {
  const api = endPoint.product + "products";
  try {
    const response = await axios.post(api, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 201) {
      return response.data;
    }
    // console.log(response);
  } catch (err) {
    console.log("Error:", err);
    return [];
  }
}

export async function GetAllProduct() {
  const api = endPoint.inventory + "products";
  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      // return response.data;
    }
    console.log(response);
  } catch (err) {
    console.log("Error:", err);
    return [];
  }
}

export async function GetSingleProduct(productId) {
  const api = endPoint.inventory + `inventories/${productId}/inventory`;
  try {
    const response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    // console.log(response);
  } catch (err) {
    console.log("Error:", err);
    return [];
  }
}
