import React from 'react'

const MedicineGroup = () => {
    return (
        <div>
            <h3>Medicine Group</h3>

            <div class=" bg-slate-200/80 rounded border border-gray-800/30">

                <p class="px-3 py-2 text-gray-800 text-sm font-normal font-poppins">Select</p>

            </div>
        </div>
    )
}

export default MedicineGroup