import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import { MdAdd, MdOutlineDeleteOutline } from "react-icons/md";
import { RiArrowDropRightLine } from "react-icons/ri";
import SearchBody from '../../../shared/components/SearchBody/SearchBody';
import MedicineGroupTable from './MedicineGroupTable/MedicineGroupTable';
import { Link } from 'react-router-dom';



const SingleMedicineGroupsHome = () => {
    const header = 'Generic Medicine',
        subHeader = "Detailed view of a medicine group."

    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4'>
                    <div className='flex justify-start items-center'>
                        <h1 className='font-bold text-[#1D242E]/50 text-[24px]'>
                            Inventory
                        </h1>

                        <div>
                            <RiArrowDropRightLine size={24} />
                        </div>
                        <h1 className='font-bold text-[#1D242E]/50 text-[24px]'>
                            Medicine Groups
                        </h1>

                        <div>
                            <RiArrowDropRightLine size={24} />
                        </div>

                        <SectionHeader header={header} />



                    </div>
                    <SectionSubHeader subHeader={subHeader} />
                </div>
                <Link to='/medicine-group/new' className={` bg-[#F0483E] border text-white rounded p-3 flex justify-between items-center cursor-pointer`} >
                    <div>
                        <MdAdd size={20} fill='#fff' />
                    </div>
                    <h4>Add New Item</h4>
                </Link>

            </div>
            <div className='flex justify-between mt-3'>
                <SearchBody />

                <div className={` bg-[#fff] border border-[#1D242E]/80  rounded p-2 flex justify-between text-sm items-center cursor-pointer px-8`} >

                    <h4>Select group</h4>
                </div>

            </div>

            <div>
                <MedicineGroupTable />
            </div>

            <div className={` border-[#F0483E] border  rounded p-3 flex justify-between items-center cursor-pointer w-fit mt-4 gap-2 text-red-500`} >
                <div>
                    <MdOutlineDeleteOutline size={20} />
                </div>


                <h4 class=" text-[15px] font-normal font-['Poppins']">Remove Group</h4>
            </div>


        </div>
    )
}

export default SingleMedicineGroupsHome
