import React, { useState } from 'react'
import DashboardHero from './DashboardHero'
import style from './dHome.module.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import excel from '../../../assets/ExcelReport.xlsx';
import pdf from '../../../assets/ExcelReport.pdf';

const DashboardHome = () => {

    const [show, setShow] = useState(false)

    const handleShow = () => {
        setShow(prev => !prev)
    }

    return (
        <div>

            <div className=" flex justify-between items-center">
                <DashboardHero header='Dashboard' subHeader='A quick data overview for rehobot pharmacy' />


                <div className='relative'>
                    <div className={`${style.report} border border-[#1D242E]/90 bg-white rounded p-3 flex justify-between cursor-pointer`} onClick={handleShow}>
                        <h4>Download Report</h4>
                        <div>
                            <RiArrowDropDownLine size={20} fill='#1D242E' />
                        </div>
                    </div>

                    {
                        show &&

                        <div className='absolute -bottom-24 right-4 bg-white rounded border border-[#272c33]  '>
                            <div className='flex flex-col items-center justify-center text-base'>
                                <a href={excel} className='px-8 py-2 border-b border-gray-400'>Excel</a>
                                <a href={pdf} className='px-8 py-2 border-b border-gray-400'>Pdf</a>
                                {/* <button className='px-4 py-2 '>PDF</button> */}
                            </div>

                        </div>}
                </div>

            </div>
        </div>
    )
}

export default DashboardHome