import React from 'react'
import logo from '../../../assets/images/dawa white.png'
import { BsBack } from 'react-icons/bs'
import { AiFillLeftCircle } from 'react-icons/ai'
const SidebarHeader = ({handleSidebar}) => {
    return (
        <div className='bg-[#23826E] flex justify-between items-center py-6 pr-4'>
            <div className='flex items-center gap-1 pl-4'>
                <img src={logo} alt="" className='w-[40px]' />
            <h1 className='text-white'>awaFinder</h1>
            </div>
            <AiFillLeftCircle size={30} onClick={handleSidebar} className='hover:cursor-pointer' />
        </div>

    )
}

export default SidebarHeader