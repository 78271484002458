import React from 'react'
import DashboardHero from '../Components/DashboardHero'
import DashboardDesc from '../Components/DashboardDesc'
import FeaturedPharmacetucials from '../Components/FeaturedPharmacetucials/FeaturedPharmacetucials'
import DashboardHome from '../Components/DashboardHome'
import Summary from '../Components/Summary/Summary'

const Dashboard = () => {
    return (
        <div className='pl-8 pb-4'>
            <DashboardHome />

            <DashboardDesc />
            <DashboardHero header='Featured Pharmacetucials' subHeader='A quick data overview for rehobot pharmacy' />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>

                <FeaturedPharmacetucials />
                <FeaturedPharmacetucials />

                <FeaturedPharmacetucials />
                <FeaturedPharmacetucials />

            </div>

            <div>
                <Summary />
            </div>
        </div>

    )
}

export default Dashboard