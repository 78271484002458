import React from 'react'
import InventoryList from '../InventoryItem/Detail/Components/InventoryList'

const DetailInventoryPage = () => {
    return (
        <div className='pl-8 pb-4'>
            <InventoryList /></div>
    )
}

export default DetailInventoryPage