const InputText = ({
    label,
    type = 'text',
    defaultValue = type === 'text' ? '' : type === 'number' && 0,
    name,
    placeholder,
    register,
  }) => {
    return (
      <div className="mb-4">
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {label}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg 
          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:placeholder-gray-400"
          placeholder={placeholder}
          {...register(name)}
          defaultValue={defaultValue}
        />
      </div>
    );
  };
  
  export default InputText;
  